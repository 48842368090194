import * as React from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Container,
  Alert,
  Typography,
  Box,
  Grid,
  Link,
  CssBaseline,
  Button,
  Avatar,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { TextField } from "../../components/TextField";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { isAuthApiError, AuthError } from "@supabase/supabase-js";
import { joiResolver } from "@hookform/resolvers/joi";
import { userValidationSchema } from "../../utils/userValidationSchema";
import supabase from "../../utils/supabaseClient";
import { Password } from "../../components/usersFlow/Password";
import { Copyright } from "../../components/Copyright";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const SignUp: React.FC = () => {
  let navigate = useNavigate();

  const [error, setError] = React.useState<AuthError | null>(null);

  const { control, handleSubmit } = useForm({
    resolver: joiResolver(userValidationSchema),

    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      repeat_password: "",
    },
  });
  const onSubmit = async (data) => {
    const email = data.email;
    const password = data.password;

    if (email && password) {
      let { data, error } = await supabase.auth.signUp({
        email,
        password,
      });

      if (data.user) {
        navigate("/login/confirm");
      }
      if (error && isAuthApiError(error)) {
        setError(error);
      }
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>{" "}
          {error && (
            <Alert severity="error" sx={{ width: "100%", m: 2 }}>
              {error.message}
            </Alert>
          )}
          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Password control={control} showRepeatPassword />
                </Box>
              </Grid>
            </Grid>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Typography variant="body2">
                  Already have an account?
                  <Link href="/login" variant="body2" sx={{ ml: 1 }}>
                    Sign in
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
};
export default SignUp;
