import React from "react";
import { Controller } from "react-hook-form";
import { TextField as MUITextField } from "@mui/material";
//import { FormInputProps } from "./FormInputProps";

export const TextField = ({ name, control, label, endAdornment, ...props }: any) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={props.rules}
      render={({ field: { onChange, value, ref }, fieldState: { error }, formState }) => (
        <MUITextField
          ref={ref}
          helperText={error ? error.message : null}
          size="small"
          error={!!error}
          onChange={onChange}
          value={value}
          fullWidth
          label={label}
          variant="outlined"
          endAdornment={endAdornment}
          {...props}
        />
      )}
    />
  );
};
