import Joi from "joi";
import { validatePassword } from "./passwordValidator";

export const userValidationSchema = Joi.object({
  firstName: Joi.string().alphanum().min(3).max(30).required().messages({
    "string.empty": "First Name is required.",
    "string.min": "First Name must be at least 3 characters long.",
  }),
  lastName: Joi.string().alphanum().min(3).max(30).required().messages({
    "string.empty": "Last Name is required.",
    "string.min": "First Name must be at least 3 characters long.",
  }),
  password: Joi.string()
    .custom((value, helpers) => {
      const error = validatePassword(value);
      if (error) {
        return helpers.error("string.pattern.base");
      }
      return value;
    })
    .required()
    .messages({
      "string.empty": "Password is required.",
      "string.pattern.base":
        "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long.",
    }),

  repeat_password: Joi.string()
    .valid(Joi.ref("password"))
    .required()
    .messages({ "any.only": "Passwords must match" }),

  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.email": "Please enter a valid email address.",
      "string.empty": "Email is required.",
    }),
});
