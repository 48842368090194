import * as React from "react";

import {
  Box,
  Alert,
  Typography,
  Container,
  Avatar,
  Button,
  Grid,
  CssBaseline,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import supabase from "../../utils/supabaseClient";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { isAuthApiError, AuthError } from "@supabase/supabase-js";
import { Password } from "../../components/usersFlow/Password";
import { Copyright } from "../../components/Copyright";

export default function NewPassword() {
  let navigate = useNavigate();

  const [error, setError] = React.useState<AuthError | null>(null);

  React.useEffect(() => {
    const fetchData = async () => {
      Object.fromEntries(new URLSearchParams(window.location.search));
    };

    fetchData();
  }, []);

  const { control, handleSubmit } = useForm({
    //resolver: joiResolver(userValidationSchema),

    defaultValues: {
      email: "",
      password: "",
      repeat_password: "",
    },
  });
  const onSubmit = async (data) => {
    const newPassword = data.password;

    if (newPassword) {
      const { data, error } = await supabase.auth.updateUser({
        // email: email,
        password: newPassword,
      });

      console.log("data", data);

      if (data.user) {
        navigate("/");
      }

      if (error && isAuthApiError(error)) {
        setError(error);
      }
    }
  };
  const handleLogout = async () => {
    let { error } = await supabase.auth.signOut();
  };
  return (
    <Container component="main" maxWidth="xl">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          New password
        </Typography>
        {error && (
          <Alert severity="error" sx={{ width: "100%", m: 2 }}>
            {error.message}
          </Alert>
        )}
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          sx={{ mt: 3, display: "flex", flexDirection: "column", gap: 1, width: "350px" }}
        >
          <Password control={control} showRepeatPassword />

          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Submit
          </Button>
          <Grid container>
            {/* <Grid item xs>
              <Link href="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid> */}
            {/* <Grid item>
              <Link href="/signup" variant="body2">
                {"Sign Up"}
              </Link>
            </Grid> */}
          </Grid>
        </Box>
      </Box>
      {/* <Button onClick={handleLogout}>Log out</Button> */}

      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
