import React, { useEffect } from "react";
import { Box, Alert, Avatar, Button, CssBaseline, Typography, Container } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import supabase from "../../utils/supabaseClient";
import { useForm } from "react-hook-form";
import { TextField } from "../../components/TextField";
import { isAuthApiError, AuthError } from "@supabase/supabase-js";
import { Copyright } from "../../components/Copyright";

export default function ResetPassword() {
  const [error, setError] = React.useState<AuthError | null>(null);
  const [isResetPasswordReset, setResetPasswordReset] = React.useState<boolean>(false);

  useEffect(() => {
    const signOut = async () => {
      await supabase.auth.signOut();
    };
    signOut();
  }, []);

  const { control, handleSubmit } = useForm({
    //resolver: joiResolver(userValidationSchema),

    defaultValues: {
      email: "",
    },
  });
  const onSubmit = async (data) => {
    console.log("data", data);
    const email = data.email;

    if (email) {
      await supabase.auth.resetPasswordForEmail(email);

      if (data) {
        setResetPasswordReset(true);
      }

      if (error && isAuthApiError(error)) {
        setError(error);
      }
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xl"
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "350px",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        {error && (
          <Alert severity="error" sx={{ width: "100%", m: 2 }}>
            {error.message}
          </Alert>
        )}
        {isResetPasswordReset && (
          <Alert severity="error" sx={{ width: "100%", m: 2 }}>
            Email with a link to reset your password has been sent to your email address.
          </Alert>
        )}
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          sx={{ mt: 3, display: "flex", flexDirection: "column", gap: 1, width: "350px" }}
        >
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            control={control}
          />

          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Reset password
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
