import React, { createContext, useState, useEffect, useContext } from "react";
import supabase from "./utils/supabaseClient";

const AuthContext = createContext({ user: null, isProcessing: true });

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    const getSession = async () => {
      return await supabase.auth.getSession();
    };
    getSession()
      .then((session) => setUser(session.data.session || null))
      .catch((e) => console.log("error", e));

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user ? session.user : null);
      setIsProcessing(false);
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  return <AuthContext.Provider value={{ user, isProcessing }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
