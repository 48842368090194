import React from "react";
import { useAuth } from "../AuthContext";
import { Navigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

const ProtectedRoute = ({ children }) => {
  const { user, isProcessing } = useAuth();

  if (isProcessing) return <CircularProgress />;

  return user ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
