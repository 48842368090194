import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import { SystemsList } from "./SystemsList";
import { AddSystemForm } from "./AddSystemForm";
import { EditSystemForm } from "./EditSystemForm";
import { SystemShow } from "./SystemShow";
import ProtectedRoute from "../components/ProtectedRoute";
import SignUp from "../pages/usersFlow/SignUp";
import SignIn from "../pages/usersFlow/SignIn";
import ResetPassword from "../pages/usersFlow/ResetPassword";
import NewPassword from "../pages/usersFlow/NewPassword";
import { AuthProvider } from "../AuthContext";
import Navbar from "../components/Navbar";

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route
            path="/systems/add"
            element={
              <ProtectedRoute>
                <AddSystemForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/systems/:id/edit"
            element={
              <ProtectedRoute>
                <EditSystemForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/systems/:id"
            element={
              <ProtectedRoute>
                <SystemShow />
              </ProtectedRoute>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <SystemsList />
              </ProtectedRoute>
            }
          />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<SignIn />} />

          <Route path="/forgot-password" element={<ResetPassword />} />
          <Route path="/new-password?" element={<NewPassword />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};
