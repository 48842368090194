import { Box, Button } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import supabase from "../utils/supabaseClient";

export interface ISystemListProps {
  systems?: { name: string; id: string }[];
}

// interface ISystemState {
//   systemsData?: {
//     items: any[];
//     error: any;
//   };
// }
export const SystemsList = (props: ISystemListProps) => {
  const [systems, setSystems] = useState<{ name: string; id: string }[]>();

  useEffect(() => {
    const fetchData = async () => {
      let { data: systems, error } = await supabase.from("systems").select("*");

      //const systemsData = await getDocuments("systems");
      setSystems(systems as { name: string; id: string }[]);
    };

    fetchData();
  }, []);

  // console.log("sysrender", systems?.systemsData?.items);
  // console.log("error", systems?.systemsData?.error);

  const handleLogout = async () => {
    let { error } = await supabase.auth.signOut();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Button onClick={handleLogout}>Log out</Button>
      Systems
      <Link to="/systems/add">Add system</Link>
      {systems?.map((item) => {
        return (
          <Link to={`/systems/${item.id}`} key={item.id}>
            <div className="flex">
              <p>{item.name}</p>
            </div>
          </Link>
        );
      })}
    </Box>
  );
};
