import React from "react";
import { Button, Typography, Container, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { TextField } from "../components/TextField";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import supabase from "../utils/supabaseClient";

export function AddSystemForm() {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: "",
      minHeight: 0,
      maxHeight: 0,
      cameraTilt: 0,
      maxDollySpeed: 0,
      connectionType: "",
    },
  });

  const navigate = useNavigate();

  const onSubmit = async (formData) => {
    //await addData("systems", data);
    console.log("formData", formData);

    const { data, error } = await supabase.from("systems").insert([formData]).select();

    navigate("/");
  };

  return (
    <Container maxWidth="sm">
      <Link to="/">back</Link>

      <Box sx={{ my: 4, p: 2, boxShadow: 1, borderRadius: 1 }}>
        <Typography variant="h5" gutterBottom>
          Add new system
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField name="name" label="Name" control={control} />

            <TextField
              label="Min height [cm]"
              id="minHeight"
              name="minHeight"
              variant="outlined"
              type="number"
              control={control}
            />

            <TextField
              label="Max height [cm]"
              id="maxHeight"
              name="maxHeight"
              variant="outlined"
              type="number"
              control={control}
            />

            <TextField
              label="Camera tilt [o]"
              id="cameraTilt"
              name="cameraTilt"
              variant="outlined"
              type="number"
              control={control}
            />

            <TextField
              label="Max dolly speed [m/s]"
              id="maxDollySpeed"
              name="maxDollySpeed"
              variant="outlined"
              type="number"
              control={control}
            />

            <TextField
              label="Connection type"
              id="connectionType"
              name="connectionType"
              variant="outlined"
              control={control}
            />

            <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
              <Button type="submit" variant="contained">
                Add
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Container>
  );
}
