import { Typography, Link } from "@mui/material";
import React from "react";

export const Copyright = (props: any) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="#">
        Remote Vision
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};
