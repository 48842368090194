import React, { useState, useEffect } from "react";
import { Button, Typography, Container, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { TextField } from "../components/TextField";
import { useNavigate } from "react-router-dom";
import supabase from "../utils/supabaseClient";

interface ISystemShow {
  [k: string]: any;
}

export function EditSystemForm() {
  const params = useParams<{ id: string }>();

  const [system, setSystem] = useState<ISystemShow>();

  useEffect(() => {
    const fetchData = async () => {
      let { data: system, error } = await supabase
        .from("systems")
        .select("*")
        .eq("id", params.id)
        .single();

      //const { data } = await getDocument("systems", params.id);
      setSystem(system as ISystemShow);
    };

    fetchData();
  }, [params.id]);

  console.log("system!!!!", system);
  const navigate = useNavigate();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      name: "",
      minHeight: 0,
      maxHeight: 0,
      cameraTilt: 0,
      maxDollySpeed: 0,
      connectionType: "",
    },
  });

  useEffect(() => {
    if (system) {
      reset(system);
    }
  }, [system, reset]);
  const onSubmit = async (data) => {
    const { error } = await supabase.from("systems").update(data).eq("id", params.id).select();

    //await updateData("systems", params.id, data);
    navigate("/");
  };

  return (
    <Container maxWidth="sm">
      <Link to="/">back</Link>

      <Box sx={{ my: 4, p: 2, boxShadow: 1, borderRadius: 1 }}>
        <Typography variant="h5" gutterBottom>
          Edit system
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField name="name" label="Name" control={control} />

            <TextField
              label="Min height [cm]"
              id="minHeight"
              name="minHeight"
              variant="outlined"
              type="number"
              control={control}
            />

            <TextField
              label="Max height [cm]"
              id="maxHeight"
              name="maxHeight"
              variant="outlined"
              type="number"
              control={control}
            />

            <TextField
              label="Camera tilt [o]"
              id="cameraTilt"
              name="cameraTilt"
              variant="outlined"
              type="number"
              control={control}
            />

            <TextField
              label="Max dolly speed [m/s]"
              id="maxDollySpeed"
              name="maxDollySpeed"
              variant="outlined"
              type="number"
              control={control}
            />

            <TextField
              label="Connection type"
              id="connectionType"
              name="connectionType"
              variant="outlined"
              control={control}
            />

            <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
              <Button type="submit" variant="contained">
                Update
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Container>
  );
}
