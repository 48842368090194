import "./App.css";
import React from "react";
import { Router } from "./pages/Router"

// import { initializeApp } from 'firebase/app';

// const firebaseConfig = {
//     apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
//     authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
//     clientEmail: process.env.NEXT_PUBLIC_FIREBASE_CLIENT_EMAIL,
//     privateKey: process.env.NEXT_PUBLIC_FIREBASE_PRIVATE_KEY,
//     //measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
// };
// export const firebaseApp = initializeApp(firebaseConfig);
// console.log("firebaseApp", firebaseApp)


function App() {
    return <Router />;
}


export default App;