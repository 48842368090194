import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
  Button,
} from "@mui/material";
import * as React from "react";
import { Link, json, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import supabase from "../utils/supabaseClient";

interface ISystemShow {
  [k: string]: any;
}
export const SystemShow = (props: ISystemShow) => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [system, setSystem] = useState<ISystemShow>();

  useEffect(() => {
    const fetchData = async () => {
      let { data: system, error } = await supabase
        .from("systems")
        .select("*")
        .eq("id", params.id)
        .single();

      //const { data } = await getDocument("systems", params.id);
      setSystem(system as ISystemShow);
    };

    fetchData();
  }, [params.id]);

  const handleDeleteSystem = async () => {
    const { error } = await supabase.from("systems").delete().eq("id", params.id);

    //await deleteOneDocument("systems", params.id);
    navigate("/");
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {" "}
        <Link to="/">Back</Link>
        <IconButton onClick={handleDeleteSystem}>
          <DeleteIcon />
        </IconButton>
        <Link to={`/systems/${params.id}/edit`}>
          <EditIcon />
        </Link>
        {system && (
          <TableContainer component={Paper} sx={{ maxWidth: 400 }}>
            {/* //{JSON.stringify(system)} */}
            <Table aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ backgroundColor: "#add8e62b", width: "40%" }}
                  >
                    Name
                  </TableCell>

                  <TableCell align="right">{system?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ backgroundColor: "#add8e62b" }}>
                    Camera Tilt
                  </TableCell>
                  <TableCell align="right">{system?.cameraTilt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ backgroundColor: "#add8e62b" }}>
                    Connection Type
                  </TableCell>
                  <TableCell align="right">{system?.connectionType}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ backgroundColor: "#add8e62b" }}>
                    Max Dolly Speed
                  </TableCell>
                  <TableCell align="right">{system?.maxDollySpeed}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ backgroundColor: "#add8e62b" }}>
                    Max Height
                  </TableCell>
                  <TableCell align="right">{system?.maxHeight}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ backgroundColor: "#add8e62b" }}>
                    Min Height
                  </TableCell>
                  <TableCell align="right">{system?.minHeight}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Box>
  );
};
