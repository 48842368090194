import * as React from "react";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Box, Alert, Avatar, Button, CssBaseline, Typography, Container } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import supabase from "../../utils/supabaseClient";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { TextField } from "../../components/TextField";
import { isAuthApiError, AuthError } from "@supabase/supabase-js";
import { Password } from "../../components/usersFlow/Password";
import { Copyright } from "../../components/Copyright";

export default function SignIn() {
  let navigate = useNavigate();
  const { key } = useParams();

  const [error, setError] = React.useState<AuthError | null>(null);

  React.useEffect(() => {
    const fetchData = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
    };

    fetchData();
  }, []);

  const { control, handleSubmit } = useForm({
    //resolver: joiResolver(userValidationSchema),

    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data) => {
    const email = data.email;
    const password = data.password;

    if (email && password) {
      let { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (data.session) {
        navigate("/");
      }

      if (error && isAuthApiError(error)) {
        setError(error);
      }
    }
  };

  const handleLogout = async () => {
    let { error } = await supabase.auth.signOut();
  };

  return (
    <Container component="main" maxWidth="xl" sx={{ width: "350px" }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        {error && (
          <Alert severity="error" sx={{ width: "100%", m: 2 }}>
            {error.message}
          </Alert>
        )}
        {key === "confirm" && (
          <Alert severity="info" sx={{ m: 1 }}>
            Please check your email for a confirmation link.
          </Alert>
        )}
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          sx={{ mt: 3, display: "flex", flexDirection: "column", gap: 2, width: "350px" }}
        >
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            control={control}
          />

          <Password control={control} />

          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                {"Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* <Button onClick={handleLogout}>Log out</Button> */}

      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
