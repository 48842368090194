import * as React from "react";
import { InputAdornment, IconButton } from "@mui/material";
import { TextField } from "../../components/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

interface IPasswordProps {
  control: any;
  showRepeatPassword?: boolean;
}

export const Password: React.FC<IPasswordProps> = (props) => {
  const { control, showRepeatPassword = false } = props;
  const [showPasswordField, setShowPasswordField] = React.useState(false);
  const [showRepeatPasswordField, setShowRepeatPasswordField] = React.useState(false);

  const handleClickShowPassword = () => setShowPasswordField((show) => !show);
  const handleClickShowRepeatPassword = () => setShowRepeatPasswordField((show) => !show);

  return (
    <>
      <TextField
        required
        fullWidth
        name="password"
        label="Password"
        type={showPasswordField ? "text" : "password"}
        id="password"
        autoComplete="new-password"
        control={control}
        startAdornment={<VisibilityOff />}
        InputProps={{
          //readOnly: true,
          endAdornment: (
            <InputAdornment position="start">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPasswordField ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {showRepeatPassword && (
        <TextField
          required
          fullWidth
          name="repeat_password"
          label="Repeat Password"
          type={showRepeatPasswordField ? "text" : "password"}
          id="password"
          autoComplete="new-password"
          control={control}
          InputProps={{
            //readOnly: true,
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowRepeatPassword}
                  edge="end"
                >
                  {showRepeatPasswordField ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    </>
  );
};
